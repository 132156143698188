
import { Vue, Component, Prop } from "vue-property-decorator"
import ActivityCard from "../../views/missions/details/ActivityCard.vue"
import ConsultantMissionActivityTimeline from "./ConsultantMissionActivityTimeline.vue"
import { MissionLifecycleBasicFragmentFragment } from "@/gql"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import SectionCard from "../../views/missions/details/SectionCard.vue"

@Component({
  components: {
    ActivityCard,
    FeedBackMessage,
    ConsultantMissionActivityTimeline,
    SectionCard,
  },
})
export default class MissionUpdates extends Vue {
  @Prop() readonly missions!: MissionLifecycleBasicFragmentFragment[]
  @Prop() readonly loading!: boolean

  getTags(mission: MissionLifecycleBasicFragmentFragment) {
    const tags = {
      country: mission.missionLead.projectLocationCountry,
      locationType: mission.missionLead.projectLocationType,
      practiceGroup: mission.missionLead.practiceGroup?.name,
      state: mission.state,
    }

    return (
      Object.entries(tags)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => !!value)
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {}
        )
    )
  }
}
