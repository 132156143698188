
import { MissionLifecycle } from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import SectionCard from "./SectionCard.vue"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"

@Component({
  components: {
    SectionCard,
    ActivityTimeline,
  },
})
export default class ActivityCard extends Vue {
  @Prop({ required: true }) readonly missionLifecycle!: MissionLifecycle
}
